import {
  Component,
  ComponentRef,
  inject,
  OnInit,
  Renderer2,
  ViewContainerRef,
} from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { TranslocoService } from "@jsverse/transloco";
import { Store } from "@ngrx/store";
import { filter, take } from "rxjs/operators";

import { RouterOutlet } from "@angular/router";
import { DomainService } from "@app/core/services/domain.service";
import { DomainsDefaultData } from "@app/shared/constants/domains";
import { LoaderComponent } from "@app/shared/loaders/loader/loader.component";
import { DomainColor } from "@app/shared/models";
import * as fromRoot from "@app/store";
import { parseQueryParam } from "./shared/utils/parse-query-param";

@Component({
  selector: "app-root",
  template: '<router-outlet (activate)="onActivate()" />',
  standalone: true,
  imports: [RouterOutlet],
})
export class AppComponent implements OnInit {
  private _componentRef!: ComponentRef<LoaderComponent> | null;

  private readonly _store$ = inject(Store<fromRoot.State>);
  private readonly _translocoService = inject(TranslocoService);
  private readonly _domain = inject(DomainService);
  private readonly _document = inject(Document);
  private readonly _renderer = inject(Renderer2);
  private readonly _viewContainerRef = inject(ViewContainerRef);
  // private readonly title = inject(Title);
  // private readonly meta = inject(Meta);
  private readonly router = inject(Router);
  // private readonly route = inject(ActivatedRoute);

  ngOnInit(): void {
    this._setSrcBitrix();
    this._addLoaderComponent();
    this._setLocale();
    this._setColor();

    this.handleAuthTicket();
  }

  onActivate(): void {
    if (this._componentRef) {
      this._componentRef.destroy();
      this._componentRef = null;
    }
  }

  private handleAuthTicket(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        take(1),
      )
      .subscribe(() => {
        const url = window.location.href;
        const authTicket = parseQueryParam(url, "auth_ticket");
        if (authTicket) {
          const encodedUrl = encodeURIComponent(url);
          this.router.navigate(["/async-redirect"], {
            queryParams: {
              redirectUrl: encodedUrl,
            },
          });
        } else {
          this._fetchData();
        }
      });
  }

  // private authenticateWithTicket(ticket: string): void {
  //   this.http.post("/auth/ticket/login", { auth_ticket: ticket }).subscribe({
  //     next: (response: any) => {
  //       if (response && response.access_token) {
  //         // Store the access token (you might want to dispatch an action here)
  //         localStorage.setItem("access_token", response.access_token);
  //         this._fetchData();
  //       } else {
  //         this.handleAuthenticationFailure();
  //       }
  //     },
  //     error: (error: HttpErrorResponse) => {
  //       if (error.status === 500) {
  //         this.handleAuthenticationFailure();
  //       } else {
  //         console.error("Authentication error:", error);
  //         this._fetchData();
  //       }
  //     },
  //   });
  // }

  private handleAuthenticationFailure(): void {
    if (!this.router.url.startsWith("/auth/login")) {
      this.router.navigate(["/auth/login"]);
    } else {
      this._fetchData();
    }
  }

  _fetchData(): void {
    this._store$.dispatch(fromRoot.checkForUpdatesApp());
    this._store$.dispatch(fromRoot.checkAuth());
  }

  _addLoaderComponent(): void {
    this._componentRef =
      this._viewContainerRef.createComponent(LoaderComponent);
    this._componentRef.instance.isLoaderTextActive = true;
  }

  _setLocale(): void {
    const locale = this._domain.getLang() || DomainsDefaultData.lang;

    this._translocoService.setActiveLang(locale);
    // this._setMeta(locale);
  }

  /* todo-kris проверить надо ли динамически менять, если нет отдельной страницы для домена */
  // _setMeta(locale: string): void {
  //   const title = this._domain.getDataDomain()?.meta?.title || DomainsDefaultData.meta.title;
  //
  //   this.title.setTitle(title);
  //
  //   forkJoin(this._translocoService.selectTranslate('meta.description', { title }, locale))
  //     .subscribe((res: string[]): void => {
  //       const [description] = res;
  //
  //       this.meta.updateTag({ name: 'description', content: description });
  //       this.meta.updateTag({ name: 'application-name', content: title });
  //       this.meta.updateTag({ name: 'url', content: this._domain.getDataDomain()?.link || DomainsDefaultData.link });
  //
  //         this.meta.addTags([
  //         { property: 'og:title', content: title },
  //         { property: 'og:description', content: description },
  //       ]);
  //     });
  // }

  // _setFavicon(): void {
  //   const hostname = DomainsData[this._domain.getDomain()];
  //   const idIconFavicon = document.querySelector('link[id=\'iconFavicon\']');
  //   const idIconFaviconSvg = document.querySelector('link[id=\'iconFaviconSvg\']');
  //   const appleTouchIcon57 = document.querySelector('link[sizes=\'57x57\']');
  //   const appleTouchIcon180 = document.querySelector('link[sizes=\'180x180\']');
  //   const iconFaviconMaskIcon = document.querySelector('link[rel=\'mask-icon\']');
  //
  //   const nameIconFavicon = hostname?.favicon || DomainsDefaultData.favicon;
  //
  //   idIconFavicon.setAttribute('href', `assets/favicon/${ nameIconFavicon }-favicon-16x16.ico`);
  //   idIconFaviconSvg.setAttribute('href', `assets/img/utils/${ nameIconFavicon }.svg`);
  //   appleTouchIcon57.setAttribute('href', `assets/favicon/${ nameIconFavicon }-favicon-57x57.png`);
  //   appleTouchIcon180.setAttribute('href', `assets/favicon/${ nameIconFavicon }-favicon-180x180.png`);
  //   iconFaviconMaskIcon.setAttribute('href', `assets/img/utils/${ nameIconFavicon }.svg`);
  // }

  _setColor(): void {
    this._renderer.addClass(
      this._document.body,
      this._domain.getColoredDomain() || DomainColor.DEFAULT,
    );
  }

  _setSrcBitrix(): void {
    const srcValue = this._domain.getDataDomain()?.meta?.srcBitrix;

    if (srcValue) {
      const script = this._renderer.createElement("script");

      script.type = "text/javascript";
      script.innerHTML = `
    const src = 'https://cdn-ru.bitrix24.ru/b16245406/crm/site_button/${srcValue}.js';
    (function(w, d, u) {
      var s = d.createElement('script');
      s.async = true;
      s.src = u + '?' + (Date.now() / 60000 | 0);
      var h = d.getElementsByTagName('script')[0];
      h.parentNode.insertBefore(s, h);
    })(window, document, src);
  `;

      this._renderer.appendChild(document.head, script);
    }
  }
}
